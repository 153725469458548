import React from "react";

import Hero from "./hero";
import Applications from "./applications";
import WhatWeDo from "./what";
import HowItWorks from "./how";

import SEO from "../../components/seo";
import Footer from "./footer";

const IndexPage = () => {
  return (
    <>
      <SEO title="Primis Group • Gravity is overrated" />

      <div
        css={`
          position: relative;
          z-index: 2;
        `}
      >
        <Hero />
      </div>

      <div
        css={`
          position: relative;
          z-index: 3;
          background-color: #0d0d0d;
        `}
      >
        <WhatWeDo />
        <Applications />
        <HowItWorks />
      </div>

      <Footer />
    </>
  );
};

export default IndexPage;
