import React from "react";
import styled from "styled-components/macro";
import { TimelineLite } from "gsap";

import { Link } from "gatsby";

import { Wrapper } from "./components";
import { SmallTitle, BodyText, Subtitle } from "../../components/text";

import useEntryAnimation from "../../utility/useEntryAnimation";
import useScrollAnimation from "../../utility/useScrollAnimation";

import menu from "./applications.json";
import Button from "../../components/button";

import MouseTrap from "../../lib/mouse-thing/components/MouseTrap";

import content from "../../content/home.yml";

const Application = React.forwardRef(function Application(props, $container) {
  const { active } = props;

  return (
    <BodyText fontSize={3} mb={4}>
      <div
        css={`
          width: 100%;
        `}
        ref={$container}
      >
        <SmallTitle data-reveal>
          {content[process.env.GATSBY_LANG].applications.title}
        </SmallTitle>
        <Subtitle data-reveal my={3} fontSize={4}>
          {content[process.env.GATSBY_LANG].applications.menu[active].label}
        </Subtitle>

        <div
          css={`
            display: grid;
            @media screen and (min-width: 800px) {
              grid-template-columns: 1fr 0.5fr 1fr;
            }
          `}
        >
          <BodyText data-reveal="lines" my={3} color="mute">
            {content[process.env.GATSBY_LANG].applications.menu[active].text}
          </BodyText>
          <div></div>
          <div
            css={`
              width: 100%;
              margin: 16px 0;
              display: grid;
              grid-template-columns: 1fr;
              grid-column-gap: 0.2rem;
              grid-row-gap: 0.2rem;
              font-size: 16px;
              text-align: left;

              @media screen and (min-width: 800px) {
                grid-column-gap: 0.5rem;
                grid-row-gap: 0.5rem;
                font-size: 16px;
                text-align: right;
              }

              div {
                list-style: bullet;
                :not(:last-child) {
                  :after {
                    content: "";
                    height: 1px;
                    background: linear-gradient(
                      90deg,
                      rgba(255, 255, 255, 1),
                      rgba(0, 0, 0, 0) 100%
                    );
                    width: 100%;
                    display: block;
                    margin-top: 0.4rem;

                    @media screen and (min-width: 800px) {
                      background: linear-gradient(
                        90deg,
                        rgba(0, 0, 0, 0),
                        rgba(255, 255, 255, 1) 100%
                      );
                      margin-top: 0.5rem;
                    }
                  }
                }
              }
            `}
          >
            {content[process.env.GATSBY_LANG].applications.menu[
              active
            ].list.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        </div>
      </div>

      <Button data-reveal as={Link} to={"/contacts"}>
        {content[process.env.GATSBY_LANG].applications.cta}
      </Button>
    </BodyText>
  );
});

function Applications() {
  const [active, setActive] = React.useState(1);

  const $container = useScrollAnimation(() => {
    const tl = new TimelineLite();

    tl.add(() => {
      animateIn();
    });

    return tl;
  });

  const [animateIn] = useEntryAnimation($container);

  function handleClick(item) {
    setActive(item);
  }

  return (
    <div
      id="applications"
      css={`
        position: relative;
        min-height: 100vh;
        padding: 8rem 0;
        overflow: hidden;
        background-color: #030307;
      `}
    >
      {/* Bg image */}
      <div
        css={`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          z-index: 9;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 0;

            transform: translateY(-50%);
            opacity: 0.3;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 50%;
            /* background: radial-gradient(
              circle,
              rgba(13, 13, 13, 0) 0%,
              rgba(13, 13, 13, 1) 80%
            ); */
            background: linear-gradient(
              180deg,
              rgba(13, 13, 13, 1),
              rgba(0, 0, 0, 0) 100%
            );
            z-index: 1;
          }
        `}
      >
        {menu.map((item, index) => (
          <img
            loading="lazy"
            src={`https://primisgroup.imgix.net/pictures/${item.img}?q=80&auto=format&w=1440`}
            alt={item.label}
            hidden={active !== index}
          />
        ))}
      </div>

      {/* Text content */}
      <div
        css={`
          position: relative;
          z-index: 10;

          @media screen and (min-width: 1280px) {
            position: absolute;
            top: 8rem;
            bottom: 8rem;
            left: 0;
            right: 0;
          }
        `}
      >
        <Wrapper
          css={`
            display: flex;
            flex-direction: column;

            height: 100%;
          `}
        >
          {typeof active === "number" && (
            <div
              css={`
                flex: 100%;
              `}
            >
              <div>
                <Application
                  ref={$container}
                  active={active}
                  setActive={setActive}
                />
              </div>
            </div>
          )}

          <ApplicationsList>
            <div className="scroll-wrapper">
              {menu.map((item, index) => (
                <MouseTrap area="anchor" key={item.label}>
                  {({ ref }) => (
                    <div className="item-wrapper" ref={ref}>
                      <ApplicationsList.Li
                        className={`link ${active === index && "active"}`}
                        onClick={() => handleClick(index)}
                      >
                        {item.label}
                      </ApplicationsList.Li>
                    </div>
                  )}
                </MouseTrap>
              ))}
            </div>
          </ApplicationsList>
        </Wrapper>
      </div>
    </div>
  );
}

const ApplicationsList = styled.div`
  list-style: none;
  margin: 0;

  .scroll-wrapper {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 800px) {
    margin-left: -2rem;
    width: calc(100% + 4rem);
    overflow-x: scroll;
    overflow-y: hidden;

    display: block;

    .scroll-wrapper {
      white-space: nowrap;
      width: auto;

      display: inline-block;

      padding-left: 2rem;
    }

    .item-wrapper {
      display: inline-block;
      padding-right: 1rem;
    }
  }
`;

ApplicationsList.Li = styled.div`
  color: white;
  opacity: 0.5;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 2.5;

  cursor: pointer;

  &:hover,
  &.active {
    opacity: 1;
  }
`;

export default Applications;
