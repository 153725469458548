import React from "react";
import { TimelineLite } from "gsap";
import styled from "styled-components/macro";

import useEntryAnimation from "../../utility/useEntryAnimation";
import useScrollAnimation from "../../utility/useScrollAnimation";

import FullHeightBox from "../../components/full-height-box";
import Box from "../../components/box";
import Button from "../../components/button";
import { BodyText, SmallTitle } from "../../components/text";

import { Wrapper } from "./components";

import content from "../../content/home.yml";

function WhatWeDo() {
  const $container = useScrollAnimation(() => {
    const tl = new TimelineLite();

    tl.add(() => {
      animateIn();
    });

    return tl;
  });

  const [animateIn] = useEntryAnimation($container);

  return (
    <Container id="overview">
      <FullHeightBox
        ref={$container}
        css={`
          position: relative;
        `}
      >
        <div
          css={`
            width: 100%;
            padding-top: ${100 / 1.7}vw;
            position: relative;
            margin-bottom: 2rem;
            display: none;

            @media screen and (min-width: 1280px) {
              padding: 0;
              display: block;

              position: absolute;
              width: 1280px;
              right: 0;
              top: 0;
              bottom: 0;
              z-index: 2;

              transform: translateX(20%);

              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: radial-gradient(
                  circle,
                  rgba(13, 13, 13, 0) 0%,
                  rgba(13, 13, 13, 1) 80%
                );
                z-index: 1;
              }
            }
          `}
        >
          <video
            autoPlay
            muted
            loop
            css={`
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 0;
            `}
          >
            <source
              src={
                "https://primisgroup-web.s3.eu-central-1.amazonaws.com/cloud/cloud.hd.webm"
              }
            />
          </video>
        </div>

        <Wrapper className="wrapper">
          <div
            css={`
              position: relative;
              z-index: 3;

              @media screen and (min-width: 1280px) {
                width: 50%;
              }
            `}
          >
            {console.log(process.env.GATSBY_LANG)}
            <Box>
              <SmallTitle data-reveal>
                {content[process.env.GATSBY_LANG].what.title}
              </SmallTitle>

              <BodyText data-reveal="lines" fontSize={3} mb={2}>
                {content[process.env.GATSBY_LANG].what.subtitle}
              </BodyText>

              <BodyText data-reveal="lines" color="mute" mb={2}>
                {content[process.env.GATSBY_LANG].what.content}
              </BodyText>

              <BodyText data-reveal="lines" color="mute">
                {content[process.env.GATSBY_LANG].what.content2}
              </BodyText>

              <Button as={"a"} data-reveal href="#contacts">
                {content[process.env.GATSBY_LANG].what.contacts}
              </Button>
            </Box>
          </div>
        </Wrapper>
      </FullHeightBox>
    </Container>
  );
}

const Container = styled.div`
  .wrapper {
    margin: 0 auto;
    padding-bottom: 2rem;
    position: relative;
  }
`;

export default WhatWeDo;
