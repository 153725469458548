import React from "react";
import { TimelineLite } from "gsap";

import FullHeightBox from "../../components/full-height-box";
import Box from "../../components/box";
import { Subtitle, SmallTitle, BodyText } from "../../components/text";

import useEntryAnimation from "../../utility/useEntryAnimation";
import useScrollAnimation from "../../utility/useScrollAnimation";

import { Wrapper } from "./components";

import softwareScreenImage from "../../images/sw-screen.jpg";
import content from "../../content/home.yml";

function HowItWorks() {
  const $container = useScrollAnimation(() => {
    const tl = new TimelineLite();

    tl.add(() => {
      animateIn();
    });

    return tl;
  });

  const [animateIn] = useEntryAnimation($container);

  return (
    <FullHeightBox
      css={`
        padding: 10rem 0;
        overflow-x: hidden;
      `}
      ref={$container}
    >
      <Wrapper
        css={`
          position: relative;
        `}
      >
        <div
          css={`
            width: 160%;
            margin-top: -4rem;
            margin-bottom: -5rem;
            margin-left: -30%;

            @media screen and (min-width: 1280px) {
              position: absolute;
              right: -220px;
              top: -80px;
              width: 1200px;
              z-index: 2;
            }
          `}
        >
          <img src={softwareScreenImage} alt="Primis Live ®" />
        </div>
        <div
          css={`
            /* width: 600px;
            padding-right: 4rem; */
            position: relative;
            z-index: 3;
            white-space: normal;
            display: inline-block;

            @media screen and (min-width: 1280px) {
              width: 600px;
              padding-right: 4rem;
            }
          `}
        >
          <Box mb={5}>
            <SmallTitle data-reveal>
              {content[process.env.GATSBY_LANG].how.title}
            </SmallTitle>

            <Subtitle data-reveal="lines" my={3} fontSize={4}>
              {content[process.env.GATSBY_LANG].how.subtitle}
            </Subtitle>

            <BodyText
              data-reveal="lines"
              my={3}
              color="mute"
              dangerouslySetInnerHTML={{
                __html: content[process.env.GATSBY_LANG].how.content,
              }}
            />
          </Box>
        </div>

        <div
          css={`
            position: relative;
            z-index: 3;
          `}
        >
          <div
            css={`
              font-size: 0.85em;
              color: #aaa;
              line-height: 2;

              > * {
                padding: 2em;
                padding-top: 1em;
                padding-bottom: 2rem;
                /* background: linear-gradient(
                  45deg,
                  #06080f 0%,
                  #050201 44%,
                  #000000 100%
                ); */
                background: rgba(0, 0, 0, 0.3);
              }

              > * + * {
                margin-top: 1rem;
              }

              @media screen and (min-width: 1280px) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 1rem;

                > * + * {
                  margin-left: 1rem;
                  margin-top: 0;
                }
              }
            `}
          >
            <div data-reveal>
              <SmallTitle>01</SmallTitle>
              <Subtitle my={4}>
                {content[process.env.GATSBY_LANG].how.acquisition}
              </Subtitle>

              <BodyText
                color="mute"
                dangerouslySetInnerHTML={{
                  __html:
                    content[process.env.GATSBY_LANG].how.acquisition_content,
                }}
              />
            </div>

            <div data-reveal>
              <SmallTitle>02</SmallTitle>
              <Subtitle my={4}>
                {content[process.env.GATSBY_LANG].how.processing}
              </Subtitle>

              <BodyText
                color="mute"
                dangerouslySetInnerHTML={{
                  __html:
                    content[process.env.GATSBY_LANG].how.processing_content,
                }}
              />
            </div>

            <div data-reveal>
              <SmallTitle>03</SmallTitle>
              <Subtitle my={4}>
                {content[process.env.GATSBY_LANG].how.management}
              </Subtitle>
              <BodyText
                color="mute"
                dangerouslySetInnerHTML={{
                  __html:
                    content[process.env.GATSBY_LANG].how.management_content,
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </FullHeightBox>
  );
}

export default HowItWorks;
